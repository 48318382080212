@use "../../styles/partials/fonts";
@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: eurostile-extended;
  padding: 2rem;
  height: 100%;
  width: 100%;
  max-width: none;
  max-height: 100vh;

  &__icons {
    position: absolute;
    display: flex;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    top: 0;
    right: 0;
    font-size: xx-large;
  }

  &__icon {
    font-size: 2rem;
    z-index: 1;

    @include desktop {
      font-size: 2.5rem;
    }

    :hover {
      transition: 0.3s ease-in-out;
      scale: 1.03;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;

    &--top {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include desktop {
        flex-direction: row;
        align-items: baseline;
        gap: 1rem;
      }
    }

    &--bottom {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include desktop {
        align-self: flex-end;
      }
    }

    &--work {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      bottom: 8rem;
      width: 100%;
      z-index: 0;

      @include desktop {
        align-items: flex-start;
        top: .25rem;
        left: 1rem;
      }

      &-text{
        font-size: medium;
      }

      &-link {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include desktop {
          align-items: flex-start;
        }

        :hover {
          transition: 0.3s ease-in-out;
          scale: 1.03;
        }
      }

      &-image {
        width: 22%;
        max-width: 32rem;

        @include desktop {
          width: 8%;
          max-width: none;
        }
      }
    }
  }

  &__heading {
    color: $text-primary;
    font-weight: 700;
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1rem;


    // Set up for hover animation
    background: linear-gradient(
      to right,
      $text-secondary,
      $text-secondary 50%,
      $text-primary 50%
    );

    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
    transition: background-position 400ms ease-in-out;
    cursor: pointer;

    @include desktop {
      font-size: 4rem;
    }

    &:hover {
      background-position: 0 100%;
    }

    &--sub {
      text-align: center;
      color: $text-primary;
      font-size: 1.5rem;
      font-weight: 500;
      z-index: 1;

      @include desktop {
        font-size: 2rem;
      }

      &--top {
        @extend .hero__heading--sub;
        display: none;

        @include desktop {
          display: flex;
        }
      }

      &--bottom {
        @extend .hero__heading--sub;
        margin-top: 0.5rem;
        font-size: 1.25rem;

        @include desktop {
          display: none;
        }
      }
    }
  }

  &__button {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    gap: 1rem;
    margin-top: 1rem;
    font-size: 2.5rem;
    text-decoration: none;
    color: $text-primary;
    bottom: 5vh;
    padding: 0.5rem;
    animation: fadeInBottom 1s ease-in-out;
    z-index: 1;

    &:hover {
      transition: 0.5s ease;
      background-color: $button-background-secondary;
      color: $text-white;
    }
  }
}
